<template>
  <div
    v-on-click-outside="() => (showMenu = false)"
    class="flex flex-col items-center mx-auto mb-5"
  >
    <div class="w-full h-full">
      <div class="flex flex-col items-center relative h-full">
        <span
          v-show="!!value?.length"
          class="z-10 absolute top-0 -mt-4 left-0 text-xs bg-white text-gray-400 leading-6 ml-2-5 px-1"
        >
          {{ placeholder }}
        </span>
        <div class="w-full h-full">
          <div
            data-cy="multiselect-dropdown-toggle"
            class="py-1 px-1 flex border border-gray-200 bg-white rounded h-full z-10 cursor-pointer"
            @click="toggleMenu"
          >
            <div
              v-if="value.length"
              class="flex flex-auto flex-wrap h-full w-full"
              :class="!isAbsolutePositioned && 'overflow-hidden mr-2'"
            >
              <lf-badge
                removable
                :truncate="!isAbsolutePositioned"
                @click:remove="removeFirstOption"
              >
                <span
                  v-tooltip="{
                    content: formatOption(firstOption),
                    onShow: () => isSelectedTextTruncated
                  }"
                  ref="textContainerRef"
                  :class="
                    !isAbsolutePositioned &&
                    'block w-full overflow-hidden text-ellipsis whitespace-nowrap'
                  "
                >
                  {{ formatOption(firstOption) }}
                </span>
              </lf-badge>
              <lf-badge
                v-if="value.length > 1"
                v-tooltip="otherSelectedOptions"
                removable
                @click:remove="removeOtherOptions"
              >
                + {{ otherSelectedOptionsCount }}
              </lf-badge>
            </div>
            <span v-else class="flex items-center pl-3 cursor-pointer">
              {{ placeholder }}
            </span>
            <div
              class="text-gray-300 py-1 pl-2 pr-1 flex items-center"
              :class="!isAbsolutePositioned && 'relative w-full flex-1'"
            >
              <button
                type="button"
                class="cursor-pointer h-6 text-gray-600 outline-none focus:outline-none"
              >
                <span
                  class="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                >
                  <icon-base
                    :class="{ 'transform rotate-180': showMenu }"
                    height="6"
                    width="10"
                    icon="dropdown-arrow"
                    :icon-color="UTIL_COLORS.DEFAULT"
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="showMenu && !disabled"
          class="w-full right-0 z-10 bg-white shadow-lg rounded-md py-2-5 text-sm text-gray-500 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          :class="isAbsolutePositioned ? 'absolute mt-10' : 'mt-1'"
        >
          <div v-if="options.length > 3" class="px-2 pb-2 border-b">
            <search-input v-model="searchTerm" no-margin no-padding />
          </div>
          <div
            v-if="noResultsFound"
            data-cy="multiselect-dropdown-no-results"
            class="pt-2 px-4"
          >
            {{ $t("COMMON.NO_RESULTS") }}
          </div>
          <ul v-else class="max-h-50 overflow-y-auto">
            <li
              v-for="(option, key) in options"
              v-show="filterByName(option.name)"
              :key="key"
              :data-cy="option.id"
              class="py-2 pl-4 space-x-4 hover:bg-gray-200 flex justify-start overflow-auto cursor-pointer"
            >
              <input
                :id="`attr-option-${key}`"
                type="checkbox"
                class="h-4 w-4 border-gray-300 rounded-sm place-self-center cursor-pointer"
                :checked="value.some((attr) => attr.id === option.id)"
                :value="option.id"
                @click="handleCheckboxClick"
              />
              <label class="cursor-pointer" :for="`attr-option-${key}`">
                {{ formatOption(option.name) }}
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { ref, computed } from "vue";
import type { IAttribute, IStepAttribute } from "@/models/orchestration";
import type { MultiSelectOption } from "@/models/options";
import startCase from "lodash/startCase";
import upperFirst from "lodash/upperFirst";
import SearchInput from "./SearchInput.vue";
import { UTIL_COLORS } from "@/helpers/constants";
import { useElementStatus } from "@/hooks/elements";

const emit = defineEmits(["change"]);

const props = defineProps({
  value: {
    type: Array as PropType<IStepAttribute[] | Pick<MultiSelectOption, "id">[]>,
    default: () => []
  },
  options: {
    type: Array as PropType<IAttribute[] | MultiSelectOption[]>,
    default: () => []
  },
  placeholder: {
    type: String,
    default: ""
  },
  isAbsolutePositioned: {
    type: Boolean,
    default: true
  },
  scrollableParent: {
    type: String,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  allowTrim: {
    type: Boolean,
    default: true
  }
});

const showMenu = ref(false);
const searchTerm = ref("");

const textContainerRef = ref<HTMLElement | null>(null);
const { isTextTruncated } = useElementStatus();

const isSelectedTextTruncated = isTextTruncated(textContainerRef);

const firstOption = computed(() => {
  const attrId = props.value[0].id;
  return props.options.find((attr) => attr.id === attrId)?.name ?? "";
});

const otherSelectedOptionsCount = computed(() => props.value.length - 1);

const otherSelectedOptions = computed(() =>
  props.value
    .slice(1)
    .map((attr) => {
      const attribute = props.options.find(
        (attribute) => attribute.id === attr.id
      );
      return attribute?.name || "";
    })
    .filter(Boolean)
    .join(", ")
);

const formatOption = (option: string): string => {
  return props.allowTrim
    ? startCase(option)
    : option
        .split(" ")
        .map((word) => upperFirst(word))
        .join(" ");
};

const toggleMenu = () => {
  if (props.disabled) {
    return;
  }

  showMenu.value = !showMenu.value;
};

const filterByName = (option: string) =>
  !searchTerm.value ||
  option.toLowerCase().includes(searchTerm.value.toLowerCase());

const noResultsFound = computed(() => {
  return (
    !props.options.length ||
    !props.options.some((option) => filterByName(option.name))
  );
});

const removeFirstOption = () => {
  if (props.disabled) {
    return;
  }

  const [, ...rest] = props.value;
  emit("change", rest);
};

const removeOtherOptions = () => {
  if (props.disabled) {
    return;
  }

  const [first] = props.value;
  emit("change", [first]);
};

const optionIsIStepAttribute = (
  option: IAttribute | MultiSelectOption
): option is IAttribute => "context" in option;

const handleCheckboxClick = (event: MouseEvent) => {
  const { value, checked } = event.target as HTMLInputElement;

  if (!checked) {
    emit(
      "change",
      props.value.filter((attr) => String(attr.id) !== String(value))
    );
    return;
  }

  const option = props.options.find(
    (option) => String(option.id) === String(value)
  );

  if (!option) {
    return props.value;
  }

  const newValue: IStepAttribute = {
    id: option.id
  };

  if (
    optionIsIStepAttribute(option) &&
    option?.options?.refresh_ttl_hours != null
  ) {
    newValue.options = {
      refresh_ttl_hours: Number(option.options.refresh_ttl_hours)
    };
  }

  emit("change", [...props.value, newValue]);
};
</script>
